import { produce } from "immer";
import { bulkWithdrawals } from "../constants";
import { chunk } from "lodash";

const IDLE_STATUS = 0;
const IN_PROGRESS_STATUS = 1;
const DONE_STATUS = 2;

const initialState = {
  status: IDLE_STATUS,
  failed: 0,
  succeeded: 0,
  withdrawals: {},
  total: 0,
  elapsedTime: 0,
  requests: [],
  currentRequest: 0,
  chunks: [],
  currentChunk: 0,
};

const bulkWithdrawalsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case bulkWithdrawals.START: {
        if (action.data && action.data.requests) {
          const { requests } = action.data;
          draft.status = IN_PROGRESS_STATUS;
          draft.requests = requests;
          draft.total = requests.length;

          const batchSize = window.ini?.REACT_APP_BULK_TOOL_BATCH_SIZE ?? bulkWithdrawals.MAX_CONCURRENT_REQUESTS;
          draft.chunks = chunk(draft.requests, batchSize);
          draft.currentChunk = 0;
          draft.elapsedTime = 0;
          draft.estimatedRemainingTime = 0
        }
        break;
      }
      case bulkWithdrawals.DONE: {
        if (draft.currentChunk >= draft.chunks.length) {
          console.warn("Reducer: DONE action, no more chunks to process");
          draft.status = DONE_STATUS;
          draft.estimatedRemainingTime = 0;
          break;
        }

        if (action.data && action.data.results) {
          const { results, elapsedTime, currentChunk  } = action.data;

          console.warn("Reducer: DONE action", { elapsedTime, currentChunk });
          console.log("Reducer: DONE action", {results});
          draft.elapsedTime += elapsedTime;
          const currentChunkLength = draft.chunks[currentChunk].length;
          const averageTimePerRequest = elapsedTime / currentChunkLength;
          const remainingRequests = draft.chunks.slice(currentChunk + 1).reduce((sum, chunk) => sum + chunk.length, 0);
          draft.estimatedRemainingTime = Math.ceil(remainingRequests * averageTimePerRequest);
          console.log(`Estimated remaining time after chunk ${currentChunk + 1}: ${draft.estimatedRemainingTime} ms`);

          for (const result of results) {
            const request = result.requestData;
            let originalRequestIndex = request.indexInChunk;
            let originalRequest = draft.requests[originalRequestIndex];
            let isSameRequest = originalRequest != null;
            if (isSameRequest) {
              isSameRequest = request.withdrawId === originalRequest.withdrawId;
            }
            if (!isSameRequest) {
              originalRequestIndex = draft.requests.findIndex(r => {
                return !(request.withdrawId == null || r.withdrawId !== request.withdrawId);
              });
            }
            if (originalRequestIndex > -1 && result.response) {
              draft.requests[originalRequestIndex].response = result.response;
            }
            const withdrawId = request.withdrawId;
            if (result.success) {
              draft.withdrawals[withdrawId] = "Success";
              draft.succeeded += 1;
            } else {
              draft.withdrawals[withdrawId] = result?.message || "Unknown Error";
              draft.failed += 1;
            }
          }
          draft.currentChunk = currentChunk + 1;
        } else {
          console.error("Reducer: Missing or invalid results in DONE action");
        }
        break;
      }
      case bulkWithdrawals.CLEAR: {
        draft.status = IDLE_STATUS;
        draft.failed = 0;
        draft.succeeded = 0;
        draft.withdrawals = {};
        draft.total = 0;
        draft.elapsedTime = 0;
        draft.estimatedRemainingTime = null;
        draft.requests = [];
        draft.chunks = [];
        draft.currentChunk = 0;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default bulkWithdrawalsReducer;