import {all, call, put, takeEvery, delay} from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "../../store";
import {bulkPrizeGrant} from "../constants";
import {bulkPrizeGrantDone} from "../actions/bulk-prize-grant";
import {buildApiUrl} from "../../utils/api";

const apiCoreUrl = buildApiUrl("/admin/player-prizes/create");

function* processSingleRequest(requestData, currentRequest) {
  const randomStartDelay = Math.random() * 500;  // between 0-500ms
  yield delay(randomStartDelay);

  let attempts = 0;
  let shouldRetry = true;

  // const maxRetries = window.ini?.REACT_APP_BULK_TOOL_MAX_RETRIES ?? bulkPrizeGrant.MAX_RETRIES;
  const maxRetries = 1;

  while (attempts < maxRetries && shouldRetry) {
    try {
      console.log(`Processing request ${currentRequest}... Attempt ${attempts + 1}`);
      if (requestData.length === 1) {
        const {tenant_id = '-1'} = requestData[0];
        const {data} = yield call(axios.post, `${apiCoreUrl}/single?tenant_id=${tenant_id}`, requestData[0]);
        return {
          success: true,
          requestData: requestData,
          response: [data],
          indexInChunk: currentRequest,
        };
      } else {
        const firstReq = requestData[0] ?? {};
        const {tenant_id = '-1'} = firstReq;
        const {data} = yield call(axios.post, `${apiCoreUrl}/multiple?tenant_id=${tenant_id}`, requestData);
        return {
          success: true,
          requestData,
          response: data,
          indexInChunk: currentRequest,
        };
      }
    } catch (e) {
      attempts++;
      console.error(`Processing request ${currentRequest}... Attempt ${attempts} failed: ${e.toString()}`);

      // shouldRetry = e?.response?.status === 500;
      shouldRetry = false;

      if (!shouldRetry || attempts >= maxRetries) {

        let message = e.toString();
        if (e?.response?.data?.error) {
          message = e.response.data.error;
        }
        return {
          success: false,
          message,
          requestData,
          indexInChunk: currentRequest,
        };
      }

      // Calculate exponential backoff delay with jitter
      const baseDelay = 1000;  // Initial delay (1s)
      const backoffDelay = baseDelay * 2 ** (attempts - 1);  // Exponential backoff
      const jitter = Math.random() * 500;  // Random jitter (0-500 ms)

      yield delay(backoffDelay + jitter);
    }
  }
}

function* bulkPrizeGrantProgressSaga(action) {
  //processing current chunk
  const startTime = +new Date(); // Track global start time
  const { bulkPrizeGrant: state } = store.getState();
  const { chunks, currentChunk } = state;

  console.warn("Saga: Progressing bulk prize grant", {currentChunk});
  // const maxConcurrentRequests = window.ini?.REACT_APP_BULK_TOOL_BATCH_SIZE ?? bulkPrizeGrant.MAX_CONCURRENT_REQUESTS;

  // console.log(chunks);
  if (currentChunk >= chunks.length) {
    yield put(bulkPrizeGrantDone({
      results: [],
      currentChunk: currentChunk,
      elapsedTime: 0,
    }));
    return;
  }

  const chunkStartTime = +new Date();
  const results = yield all([
    call(processSingleRequest, chunks[currentChunk], currentChunk)
  ]);
  const chunkEndTime = +new Date();
  const totalElapsedTime = chunkEndTime - startTime;
  console.log("Chunk processed. Chunk time:", chunkEndTime - chunkStartTime, "ms");
  console.log("Total elapsed time:", totalElapsedTime, "ms");

  yield put(bulkPrizeGrantDone({
    results: results.map((result, index) => ({
      ...result,
    })),
    currentChunk: currentChunk,
    elapsedTime: totalElapsedTime,
  }));
}


export default function* watchBulkPrizeGrantSaga() {
  yield takeEvery(bulkPrizeGrant.PROGRESS, bulkPrizeGrantProgressSaga);
}
